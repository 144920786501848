@font-face {
	font-family: "bentley-icons-generic-webfont";
	src: url("bentley-icons-generic-webfont.eot?7aacf6f2d47c713bcfaf2d7404b8de2a?#iefix") format("embedded-opentype"),
url("bentley-icons-generic-webfont.woff2?7aacf6f2d47c713bcfaf2d7404b8de2a") format("woff2"),
url("bentley-icons-generic-webfont.woff?7aacf6f2d47c713bcfaf2d7404b8de2a") format("woff"),
url("bentley-icons-generic-webfont.ttf?7aacf6f2d47c713bcfaf2d7404b8de2a") format("truetype"),
url("bentley-icons-generic-webfont.svg?7aacf6f2d47c713bcfaf2d7404b8de2a#bentley-icons-generic-webfont") format("svg");
}

.icon {
	font-family: bentley-icons-generic-webfont !important;
	speak: none;
	font-style: normal;
	font-weight: normal !important;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-2d:before { content: "\f101"; }
.icon-3d-photo:before { content: "\f102"; }
.icon-3d-render:before { content: "\f103"; }
.icon-3d:before { content: "\f104"; }
.icon-accusnaps:before { content: "\f105"; }
.icon-active:before { content: "\f106"; }
.icon-activity:before { content: "\f107"; }
.icon-add-circular:before { content: "\f108"; }
.icon-add:before { content: "\f109"; }
.icon-airplane:before { content: "\f10a"; }
.icon-airport:before { content: "\f10b"; }
.icon-alarm-alarm:before { content: "\f10c"; }
.icon-alarm-critical-alarm:before { content: "\f10d"; }
.icon-alarm-early-warning:before { content: "\f10e"; }
.icon-alarm-priority-1:before { content: "\f10f"; }
.icon-alarm-priority-2:before { content: "\f110"; }
.icon-alarm-priority-3:before { content: "\f111"; }
.icon-alarm-priority-4:before { content: "\f112"; }
.icon-alarm-warning:before { content: "\f113"; }
.icon-alarms-alarm:before { content: "\f114"; }
.icon-alarms-critical-alarm:before { content: "\f115"; }
.icon-alarms-early-warning:before { content: "\f116"; }
.icon-alarms-priority-1:before { content: "\f117"; }
.icon-alarms-priority-2:before { content: "\f118"; }
.icon-alarms-priority-3:before { content: "\f119"; }
.icon-alarms-priority-4:before { content: "\f11a"; }
.icon-alarms-warning:before { content: "\f11b"; }
.icon-align-align-bottom:before { content: "\f11c"; }
.icon-align-align-center-horizontal:before { content: "\f11d"; }
.icon-align-align-center-vertical:before { content: "\f11e"; }
.icon-align-align-left:before { content: "\f11f"; }
.icon-align-align-right:before { content: "\f120"; }
.icon-align-align-top:before { content: "\f121"; }
.icon-angle-measure:before { content: "\f122"; }
.icon-annotation-add:before { content: "\f123"; }
.icon-annotation-info:before { content: "\f124"; }
.icon-annotation-manager:before { content: "\f125"; }
.icon-annotation-settings:before { content: "\f126"; }
.icon-annotation-show:before { content: "\f127"; }
.icon-annotation:before { content: "\f128"; }
.icon-app-1:before { content: "\f129"; }
.icon-app-2:before { content: "\f12a"; }
.icon-app-launcher:before { content: "\f12b"; }
.icon-applications:before { content: "\f12c"; }
.icon-apps-assetwise-2:before { content: "\f12d"; }
.icon-apps-assetwise:before { content: "\f12e"; }
.icon-apps-connect:before { content: "\f12f"; }
.icon-apps-issue-resolution:before { content: "\f130"; }
.icon-apps-itwin:before { content: "\f131"; }
.icon-apps-projectwise:before { content: "\f132"; }
.icon-apps-sharepoint-2:before { content: "\f133"; }
.icon-apps-sharepoint:before { content: "\f134"; }
.icon-archived:before { content: "\f135"; }
.icon-arrow-down:before { content: "\f136"; }
.icon-arrow-left:before { content: "\f137"; }
.icon-arrow-right:before { content: "\f138"; }
.icon-arrow-up:before { content: "\f139"; }
.icon-asset-classification-hide:before { content: "\f13a"; }
.icon-asset-isolate:before { content: "\f13b"; }
.icon-attach:before { content: "\f13c"; }
.icon-automatic:before { content: "\f13d"; }
.icon-automation:before { content: "\f13e"; }
.icon-away:before { content: "\f13f"; }
.icon-basket:before { content: "\f140"; }
.icon-bentley-systems:before { content: "\f141"; }
.icon-blank:before { content: "\f142"; }
.icon-bolt-2:before { content: "\f143"; }
.icon-bookmark:before { content: "\f144"; }
.icon-boundary-offset:before { content: "\f145"; }
.icon-browse-2:before { content: "\f146"; }
.icon-browse:before { content: "\f147"; }
.icon-calendar:before { content: "\f148"; }
.icon-callout:before { content: "\f149"; }
.icon-camera-animation-disabled:before { content: "\f14a"; }
.icon-camera-animation:before { content: "\f14b"; }
.icon-camera-eye:before { content: "\f14c"; }
.icon-camera-location:before { content: "\f14d"; }
.icon-camera:before { content: "\f14e"; }
.icon-caret-down-2:before { content: "\f14f"; }
.icon-caret-down:before { content: "\f150"; }
.icon-caret-left:before { content: "\f151"; }
.icon-caret-right:before { content: "\f152"; }
.icon-caret-up-2:before { content: "\f153"; }
.icon-caret-up:before { content: "\f154"; }
.icon-cell:before { content: "\f155"; }
.icon-charts:before { content: "\f156"; }
.icon-chat-new:before { content: "\f157"; }
.icon-chat:before { content: "\f158"; }
.icon-check-in-document:before { content: "\f159"; }
.icon-check-in:before { content: "\f15a"; }
.icon-check-out-document:before { content: "\f15b"; }
.icon-check-out:before { content: "\f15c"; }
.icon-checkbox-deselect:before { content: "\f15d"; }
.icon-checkbox-select:before { content: "\f15e"; }
.icon-checkmark:before { content: "\f15f"; }
.icon-chevron-down:before { content: "\f160"; }
.icon-chevron-left:before { content: "\f161"; }
.icon-chevron-right:before { content: "\f162"; }
.icon-chevron-up:before { content: "\f163"; }
.icon-circle:before { content: "\f164"; }
.icon-clear-day:before { content: "\f165"; }
.icon-clear-night:before { content: "\f166"; }
.icon-clipboard:before { content: "\f167"; }
.icon-clipboard-copy-2:before { content: "\f168"; }
.icon-clipboard-copy-3:before { content: "\f169"; }
.icon-clipboard-copy:before { content: "\f16a"; }
.icon-clipboard-cut:before { content: "\f16b"; }
.icon-clipboard-paste-2:before { content: "\f16c"; }
.icon-clipboard-paste:before { content: "\f16d"; }
.icon-clock:before { content: "\f16e"; }
.icon-close-2:before { content: "\f16f"; }
.icon-close-circular:before { content: "\f170"; }
.icon-close:before { content: "\f171"; }
.icon-cloud-download:before { content: "\f172"; }
.icon-cloud-upload:before { content: "\f173"; }
.icon-cloud:before { content: "\f174"; }
.icon-clouds-broken:before { content: "\f175"; }
.icon-clouds-scattered-day:before { content: "\f176"; }
.icon-clouds-scattered-night:before { content: "\f177"; }
.icon-coating-system-concrete:before { content: "\f178"; }
.icon-coating-system-steelwork:before { content: "\f179"; }
.icon-collapse-all:before { content: "\f17a"; }
.icon-collapse:before { content: "\f17b"; }
.icon-comments-add:before { content: "\f17c"; }
.icon-comments:before { content: "\f17d"; }
.icon-compare:before { content: "\f17e"; }
.icon-computer:before { content: "\f17f"; }
.icon-concrete-block:before { content: "\f180"; }
.icon-consequence-failure:before { content: "\f181"; }
.icon-construction-work-area:before { content: "\f182"; }
.icon-convert:before { content: "\f183"; }
.icon-crop:before { content: "\f184"; }
.icon-cross-section-alignment:before { content: "\f185"; }
.icon-cross-section-dynamic:before { content: "\f186"; }
.icon-cross-section:before { content: "\f187"; }
.icon-cube-faces-bottom:before { content: "\f188"; }
.icon-cube-faces-front:before { content: "\f189"; }
.icon-cube-faces-iso-left:before { content: "\f18a"; }
.icon-cube-faces-iso-right:before { content: "\f18b"; }
.icon-cube-faces-left:before { content: "\f18c"; }
.icon-cube-faces-none:before { content: "\f18d"; }
.icon-cube-faces-rear:before { content: "\f18e"; }
.icon-cube-faces-right:before { content: "\f18f"; }
.icon-cube-faces-top:before { content: "\f190"; }
.icon-cursor-click:before { content: "\f191"; }
.icon-cursor:before { content: "\f192"; }
.icon-dashboard-2:before { content: "\f193"; }
.icon-data-visualization:before { content: "\f194"; }
.icon-database:before { content: "\f195"; }
.icon-delete:before { content: "\f196"; }
.icon-deliverable:before { content: "\f197"; }
.icon-design-review:before { content: "\f198"; }
.icon-details:before { content: "\f199"; }
.icon-developer:before { content: "\f19a"; }
.icon-disconnect:before { content: "\f19b"; }
.icon-distance:before { content: "\f19c"; }
.icon-distribute-distribute-bottom:before { content: "\f19d"; }
.icon-distribute-distribute-center-horizontal:before { content: "\f19e"; }
.icon-distribute-distribute-center-vertical:before { content: "\f19f"; }
.icon-distribute-distribute-left:before { content: "\f1a0"; }
.icon-distribute-distribute-right:before { content: "\f1a1"; }
.icon-distribute-distribute-top:before { content: "\f1a2"; }
.icon-document-compare:before { content: "\f1a3"; }
.icon-document-lock:before { content: "\f1a4"; }
.icon-document-reference:before { content: "\f1a5"; }
.icon-document-set:before { content: "\f1a6"; }
.icon-document:before { content: "\f1a7"; }
.icon-documentation:before { content: "\f1a8"; }
.icon-download:before { content: "\f1a9"; }
.icon-drag:before { content: "\f1aa"; }
.icon-draw:before { content: "\f1ab"; }
.icon-duplicate:before { content: "\f1ac"; }
.icon-ec-class:before { content: "\f1ad"; }
.icon-ec-schema:before { content: "\f1ae"; }
.icon-edit-all:before { content: "\f1af"; }
.icon-edit-object:before { content: "\f1b0"; }
.icon-edit:before { content: "\f1b1"; }
.icon-element:before { content: "\f1b2"; }
.icon-ellipse:before { content: "\f1b3"; }
.icon-email-at:before { content: "\f1b4"; }
.icon-email:before { content: "\f1b5"; }
.icon-exit:before { content: "\f1b6"; }
.icon-expand-all:before { content: "\f1b7"; }
.icon-expand:before { content: "\f1b8"; }
.icon-export:before { content: "\f1b9"; }
.icon-external-apps:before { content: "\f1ba"; }
.icon-eyedropper-drop:before { content: "\f1bb"; }
.icon-eyedropper-filled:before { content: "\f1bc"; }
.icon-eyedropper:before { content: "\f1bd"; }
.icon-feedback:before { content: "\f1be"; }
.icon-file-types-amim:before { content: "\f1bf"; }
.icon-file-types-archive:before { content: "\f1c0"; }
.icon-file-types-audio:before { content: "\f1c1"; }
.icon-file-types-autocad:before { content: "\f1c2"; }
.icon-file-types-creative-cloud:before { content: "\f1c3"; }
.icon-file-types-dgn-db-2:before { content: "\f1c4"; }
.icon-file-types-dgn-db:before { content: "\f1c5"; }
.icon-file-types-document:before { content: "\f1c6"; }
.icon-file-types-dwg:before { content: "\f1c7"; }
.icon-file-types-forms-shared-help:before { content: "\f1c8"; }
.icon-file-types-forms-shared-success:before { content: "\f1c9"; }
.icon-file-types-forms-shared:before { content: "\f1ca"; }
.icon-file-types-forms-unshared-help:before { content: "\f1cb"; }
.icon-file-types-forms-unshared-success:before { content: "\f1cc"; }
.icon-file-types-image:before { content: "\f1cd"; }
.icon-file-types-microstation:before { content: "\f1ce"; }
.icon-file-types-mism:before { content: "\f1cf"; }
.icon-file-types-msg:before { content: "\f1d0"; }
.icon-file-types-pdf-markup:before { content: "\f1d1"; }
.icon-file-types-pdf:before { content: "\f1d2"; }
.icon-file-types-ppt:before { content: "\f1d3"; }
.icon-file-types-raster:before { content: "\f1d4"; }
.icon-file-types-revit:before { content: "\f1d5"; }
.icon-file-types-rfa:before { content: "\f1d6"; }
.icon-file-types-sketchup:before { content: "\f1d7"; }
.icon-file-types-system:before { content: "\f1d8"; }
.icon-file-types-txt-2:before { content: "\f1d9"; }
.icon-file-types-txt:before { content: "\f1da"; }
.icon-file-types-unknown:before { content: "\f1db"; }
.icon-file-types-vector:before { content: "\f1dc"; }
.icon-file-types-video:before { content: "\f1dd"; }
.icon-file-types-visio:before { content: "\f1de"; }
.icon-file-types-word:before { content: "\f1df"; }
.icon-file-types-xls:before { content: "\f1e0"; }
.icon-filter-outlined:before { content: "\f1e1"; }
.icon-filter:before { content: "\f1e2"; }
.icon-find:before { content: "\f1e3"; }
.icon-fire-extinguisher:before { content: "\f1e4"; }
.icon-fit-to-view:before { content: "\f1e5"; }
.icon-flag-2:before { content: "\f1e6"; }
.icon-flag:before { content: "\f1e7"; }
.icon-folder-add:before { content: "\f1e8"; }
.icon-folder-chevron-down:before { content: "\f1e9"; }
.icon-folder-chevron-up:before { content: "\f1ea"; }
.icon-folder-export:before { content: "\f1eb"; }
.icon-folder-import:before { content: "\f1ec"; }
.icon-folder-large:before { content: "\f1ed"; }
.icon-folder-lock:before { content: "\f1ee"; }
.icon-folder-opened:before { content: "\f1ef"; }
.icon-folder-remove-2:before { content: "\f1f0"; }
.icon-folder-remove:before { content: "\f1f1"; }
.icon-folder-shortcut:before { content: "\f1f2"; }
.icon-folder:before { content: "\f1f3"; }
.icon-font-bold:before { content: "\f1f4"; }
.icon-font-color:before { content: "\f1f5"; }
.icon-font-italic:before { content: "\f1f6"; }
.icon-font-size:before { content: "\f1f7"; }
.icon-function:before { content: "\f1f8"; }
.icon-gesture-one-finger-drag:before { content: "\f1f9"; }
.icon-gesture-one-finger-tap-double:before { content: "\f1fa"; }
.icon-gesture-one-finger-tap:before { content: "\f1fb"; }
.icon-gesture-pinch:before { content: "\f1fc"; }
.icon-gesture-touch:before { content: "\f1fd"; }
.icon-gesture-two-finger-drag:before { content: "\f1fe"; }
.icon-gesture-two-finger-tap:before { content: "\f1ff"; }
.icon-gesture-two-finger:before { content: "\f200"; }
.icon-globe:before { content: "\f201"; }
.icon-graph-bar:before { content: "\f202"; }
.icon-graph-pie-chart:before { content: "\f203"; }
.icon-graph-scatter-chart:before { content: "\f204"; }
.icon-graph:before { content: "\f205"; }
.icon-group-ungroup:before { content: "\f206"; }
.icon-group:before { content: "\f207"; }
.icon-gyroscope:before { content: "\f208"; }
.icon-hand-2:before { content: "\f209"; }
.icon-help-hollow:before { content: "\f20a"; }
.icon-help:before { content: "\f20b"; }
.icon-hierarchy-tree:before { content: "\f20c"; }
.icon-history:before { content: "\f20d"; }
.icon-home:before { content: "\f20e"; }
.icon-hourglass:before { content: "\f20f"; }
.icon-hub:before { content: "\f210"; }
.icon-i-beam:before { content: "\f211"; }
.icon-image-2:before { content: "\f212"; }
.icon-image:before { content: "\f213"; }
.icon-imodel-2:before { content: "\f214"; }
.icon-imodel-hollow-2:before { content: "\f215"; }
.icon-imodel-hollow:before { content: "\f216"; }
.icon-imodel-hub-sync:before { content: "\f217"; }
.icon-imodel-hub:before { content: "\f218"; }
.icon-imodel-schema:before { content: "\f219"; }
.icon-imodel:before { content: "\f21a"; }
.icon-imodeljs:before { content: "\f21b"; }
.icon-import:before { content: "\f21c"; }
.icon-important-very:before { content: "\f21d"; }
.icon-important:before { content: "\f21e"; }
.icon-info-2:before { content: "\f21f"; }
.icon-info-hollow:before { content: "\f220"; }
.icon-info:before { content: "\f221"; }
.icon-install:before { content: "\f222"; }
.icon-isolate:before { content: "\f223"; }
.icon-issue-report:before { content: "\f224"; }
.icon-issue-resolution-add:before { content: "\f225"; }
.icon-issue-resolution:before { content: "\f226"; }
.icon-item:before { content: "\f227"; }
.icon-itwin-mapping:before { content: "\f228"; }
.icon-label:before { content: "\f229"; }
.icon-layers-hide:before { content: "\f22a"; }
.icon-layers-isolate:before { content: "\f22b"; }
.icon-layers:before { content: "\f22c"; }
.icon-lightbulb-2:before { content: "\f22d"; }
.icon-lightbulb:before { content: "\f22e"; }
.icon-line:before { content: "\f22f"; }
.icon-link:before { content: "\f230"; }
.icon-list:before { content: "\f231"; }
.icon-location:before { content: "\f232"; }
.icon-lock-unlocked:before { content: "\f233"; }
.icon-lock:before { content: "\f234"; }
.icon-manager:before { content: "\f235"; }
.icon-map:before { content: "\f236"; }
.icon-measure-2d-hide:before { content: "\f237"; }
.icon-measure-2d-show:before { content: "\f238"; }
.icon-measure-2d:before { content: "\f239"; }
.icon-measure-3d:before { content: "\f23a"; }
.icon-measure-angle:before { content: "\f23b"; }
.icon-measure-arc:before { content: "\f23c"; }
.icon-measure-area:before { content: "\f23d"; }
.icon-measure-clear-3:before { content: "\f23e"; }
.icon-measure-clear:before { content: "\f23f"; }
.icon-measure-distance:before { content: "\f240"; }
.icon-measure-location:before { content: "\f241"; }
.icon-measure-perpendicular:before { content: "\f242"; }
.icon-measure-station:before { content: "\f243"; }
.icon-measure:before { content: "\f244"; }
.icon-media-controls-circular-pause:before { content: "\f245"; }
.icon-media-controls-circular-play:before { content: "\f246"; }
.icon-media-controls-circular-stop:before { content: "\f247"; }
.icon-media-controls-fast-backward:before { content: "\f248"; }
.icon-media-controls-fast-forward:before { content: "\f249"; }
.icon-media-controls-frame-backward:before { content: "\f24a"; }
.icon-media-controls-frame-forward:before { content: "\f24b"; }
.icon-media-controls-go-to-end:before { content: "\f24c"; }
.icon-media-controls-go-to-start:before { content: "\f24d"; }
.icon-media-controls-loop:before { content: "\f24e"; }
.icon-media-controls-pause:before { content: "\f24f"; }
.icon-media-controls-play-backward:before { content: "\f250"; }
.icon-media-controls-play-section:before { content: "\f251"; }
.icon-media-controls-play:before { content: "\f252"; }
.icon-media-controls-record:before { content: "\f253"; }
.icon-media-controls-stop:before { content: "\f254"; }
.icon-menu:before { content: "\f255"; }
.icon-merge:before { content: "\f256"; }
.icon-mist:before { content: "\f257"; }
.icon-model-hide:before { content: "\f258"; }
.icon-model-isolate:before { content: "\f259"; }
.icon-model:before { content: "\f25a"; }
.icon-more-2:before { content: "\f25b"; }
.icon-more-circular-vertical:before { content: "\f25c"; }
.icon-more-circular:before { content: "\f25d"; }
.icon-more-vertical-2:before { content: "\f25e"; }
.icon-more-vertical-circular:before { content: "\f25f"; }
.icon-more-vertical:before { content: "\f260"; }
.icon-more:before { content: "\f261"; }
.icon-mouse-click-left:before { content: "\f262"; }
.icon-mouse-click-right:before { content: "\f263"; }
.icon-mouse-click-wheel:before { content: "\f264"; }
.icon-mouse-click:before { content: "\f265"; }
.icon-move-2:before { content: "\f266"; }
.icon-move-below:before { content: "\f267"; }
.icon-move:before { content: "\f268"; }
.icon-network:before { content: "\f269"; }
.icon-new:before { content: "\f26a"; }
.icon-node-query-connected:before { content: "\f26b"; }
.icon-node-query-disconnected:before { content: "\f26c"; }
.icon-node-query-downstream:before { content: "\f26d"; }
.icon-node-query-multi:before { content: "\f26e"; }
.icon-node-query-upstream:before { content: "\f26f"; }
.icon-notification-settings:before { content: "\f270"; }
.icon-notification:before { content: "\f271"; }
.icon-paintbrush:before { content: "\f272"; }
.icon-palette:before { content: "\f273"; }
.icon-panorama-model:before { content: "\f274"; }
.icon-panorama:before { content: "\f275"; }
.icon-perspective:before { content: "\f276"; }
.icon-pin:before { content: "\f277"; }
.icon-placeholder:before { content: "\f278"; }
.icon-plan-drawing:before { content: "\f279"; }
.icon-plan-floor:before { content: "\f27a"; }
.icon-plane:before { content: "\f27b"; }
.icon-polygon:before { content: "\f27c"; }
.icon-print-preview:before { content: "\f27d"; }
.icon-print-settings:before { content: "\f27e"; }
.icon-print:before { content: "\f27f"; }
.icon-probability-failure:before { content: "\f280"; }
.icon-probability:before { content: "\f281"; }
.icon-process:before { content: "\f282"; }
.icon-profile:before { content: "\f283"; }
.icon-progress-backward-2:before { content: "\f284"; }
.icon-progress-backward-3:before { content: "\f285"; }
.icon-progress-backward:before { content: "\f286"; }
.icon-progress-forward-2:before { content: "\f287"; }
.icon-progress-forward-3:before { content: "\f288"; }
.icon-progress-forward:before { content: "\f289"; }
.icon-project-large:before { content: "\f28a"; }
.icon-properties-list:before { content: "\f28b"; }
.icon-properties:before { content: "\f28c"; }
.icon-puzzle:before { content: "\f28d"; }
.icon-pw-project:before { content: "\f28e"; }
.icon-pw-workarea:before { content: "\f28f"; }
.icon-quantities:before { content: "\f290"; }
.icon-rain-day:before { content: "\f291"; }
.icon-rain-night:before { content: "\f292"; }
.icon-rain-shower:before { content: "\f293"; }
.icon-range:before { content: "\f294"; }
.icon-re-center:before { content: "\f295"; }
.icon-read-only:before { content: "\f296"; }
.icon-records:before { content: "\f297"; }
.icon-rectangle:before { content: "\f298"; }
.icon-redo:before { content: "\f299"; }
.icon-refresh:before { content: "\f29a"; }
.icon-remove-2:before { content: "\f29b"; }
.icon-remove:before { content: "\f29c"; }
.icon-rename:before { content: "\f29d"; }
.icon-reorder:before { content: "\f29e"; }
.icon-repair:before { content: "\f29f"; }
.icon-replace:before { content: "\f2a0"; }
.icon-reporting-alignment-data:before { content: "\f2a1"; }
.icon-reports:before { content: "\f2a2"; }
.icon-road:before { content: "\f2a3"; }
.icon-rotate-left:before { content: "\f2a4"; }
.icon-rotate-right:before { content: "\f2a5"; }
.icon-rotate:before { content: "\f2a6"; }
.icon-rules:before { content: "\f2a7"; }
.icon-safetibase:before { content: "\f2a8"; }
.icon-save-as:before { content: "\f2a9"; }
.icon-save-settings:before { content: "\f2aa"; }
.icon-save-update:before { content: "\f2ab"; }
.icon-save:before { content: "\f2ac"; }
.icon-saved-searches:before { content: "\f2ad"; }
.icon-saved-view-annotate:before { content: "\f2ae"; }
.icon-saved-view-chat:before { content: "\f2af"; }
.icon-saved-view:before { content: "\f2b0"; }
.icon-saved-views-move:before { content: "\f2b1"; }
.icon-scale-2:before { content: "\f2b2"; }
.icon-scale:before { content: "\f2b3"; }
.icon-screenshare-stop:before { content: "\f2b4"; }
.icon-screenshare:before { content: "\f2b5"; }
.icon-script-run:before { content: "\f2b6"; }
.icon-script-status:before { content: "\f2b7"; }
.icon-script:before { content: "\f2b8"; }
.icon-search:before { content: "\f2b9"; }
.icon-section-clear:before { content: "\f2ba"; }
.icon-section-element:before { content: "\f2bb"; }
.icon-section-hide:before { content: "\f2bc"; }
.icon-section-plane:before { content: "\f2bd"; }
.icon-section-range:before { content: "\f2be"; }
.icon-section-shape:before { content: "\f2bf"; }
.icon-section-show:before { content: "\f2c0"; }
.icon-section-tool:before { content: "\f2c1"; }
.icon-select-box:before { content: "\f2c2"; }
.icon-select-line:before { content: "\f2c3"; }
.icon-select-minus:before { content: "\f2c4"; }
.icon-select-plus:before { content: "\f2c5"; }
.icon-select-single:before { content: "\f2c6"; }
.icon-selection-clear:before { content: "\f2c7"; }
.icon-selection:before { content: "\f2c8"; }
.icon-send:before { content: "\f2c9"; }
.icon-settings:before { content: "\f2ca"; }
.icon-shape:before { content: "\f2cb"; }
.icon-share:before { content: "\f2cc"; }
.icon-shortcut-add:before { content: "\f2cd"; }
.icon-sitemap:before { content: "\f2ce"; }
.icon-smartphone:before { content: "\f2cf"; }
.icon-smiley-happy-2:before { content: "\f2d0"; }
.icon-smiley-happy-very-2:before { content: "\f2d1"; }
.icon-smiley-happy-very:before { content: "\f2d2"; }
.icon-smiley-happy:before { content: "\f2d3"; }
.icon-smiley-neutral-2:before { content: "\f2d4"; }
.icon-smiley-neutral:before { content: "\f2d5"; }
.icon-smiley-sad-2:before { content: "\f2d6"; }
.icon-smiley-sad-very-2:before { content: "\f2d7"; }
.icon-smiley-sad-very:before { content: "\f2d8"; }
.icon-smiley-sad:before { content: "\f2d9"; }
.icon-snaps-bisector:before { content: "\f2da"; }
.icon-snaps-center:before { content: "\f2db"; }
.icon-snaps-intersection:before { content: "\f2dc"; }
.icon-snaps-midpoint:before { content: "\f2dd"; }
.icon-snaps-multione:before { content: "\f2de"; }
.icon-snaps-multithree:before { content: "\f2df"; }
.icon-snaps-multitwo:before { content: "\f2e0"; }
.icon-snaps-nearest:before { content: "\f2e1"; }
.icon-snaps-origin:before { content: "\f2e2"; }
.icon-snaps:before { content: "\f2e3"; }
.icon-snow:before { content: "\f2e4"; }
.icon-sort-down:before { content: "\f2e5"; }
.icon-sort-menu:before { content: "\f2e6"; }
.icon-sort-up:before { content: "\f2e7"; }
.icon-spaces:before { content: "\f2e8"; }
.icon-spatial-view-apply:before { content: "\f2e9"; }
.icon-spin:before { content: "\f2ea"; }
.icon-star-hollow:before { content: "\f2eb"; }
.icon-star:before { content: "\f2ec"; }
.icon-status-draft:before { content: "\f2ed"; }
.icon-status-error-hollow:before { content: "\f2ee"; }
.icon-status-error:before { content: "\f2ef"; }
.icon-status-pass-hollow:before { content: "\f2f0"; }
.icon-status-pass:before { content: "\f2f1"; }
.icon-status-pending-hollow:before { content: "\f2f2"; }
.icon-status-pending:before { content: "\f2f3"; }
.icon-status-rejected-hollow:before { content: "\f2f4"; }
.icon-status-rejected:before { content: "\f2f5"; }
.icon-status-running:before { content: "\f2f6"; }
.icon-status-success-hollow:before { content: "\f2f7"; }
.icon-status-success:before { content: "\f2f8"; }
.icon-status-update:before { content: "\f2f9"; }
.icon-status-warning:before { content: "\f2fa"; }
.icon-sunrise:before { content: "\f2fb"; }
.icon-sunset:before { content: "\f2fc"; }
.icon-symbol:before { content: "\f2fd"; }
.icon-sync-disabled:before { content: "\f2fe"; }
.icon-sync:before { content: "\f2ff"; }
.icon-table-2:before { content: "\f300"; }
.icon-table-of-contents:before { content: "\f301"; }
.icon-table:before { content: "\f302"; }
.icon-tag-2:before { content: "\f303"; }
.icon-technical-preview-badge-bw:before { content: "\f304"; }
.icon-technical-preview-badge:before { content: "\f305"; }
.icon-technical-preview-bw:before { content: "\f306"; }
.icon-technical-preview-mini-bw:before { content: "\f307"; }
.icon-technical-preview-mini:before { content: "\f308"; }
.icon-technical-preview:before { content: "\f309"; }
.icon-test:before { content: "\f30a"; }
.icon-text-align-text-align-center:before { content: "\f30b"; }
.icon-text-align-text-align-justify:before { content: "\f30c"; }
.icon-text-align-text-align-left:before { content: "\f30d"; }
.icon-text-align-text-align-right:before { content: "\f30e"; }
.icon-text-medium:before { content: "\f30f"; }
.icon-text-small:before { content: "\f310"; }
.icon-text:before { content: "\f311"; }
.icon-three-points-circular-arc:before { content: "\f312"; }
.icon-thumbnails:before { content: "\f313"; }
.icon-thunderstorm:before { content: "\f314"; }
.icon-timeline:before { content: "\f315"; }
.icon-timer:before { content: "\f316"; }
.icon-tools:before { content: "\f317"; }
.icon-tree:before { content: "\f318"; }
.icon-underline:before { content: "\f319"; }
.icon-undo:before { content: "\f31a"; }
.icon-unlink:before { content: "\f31b"; }
.icon-upgrade:before { content: "\f31c"; }
.icon-upload-to-cloud:before { content: "\f31d"; }
.icon-upload:before { content: "\f31e"; }
.icon-user-add:before { content: "\f31f"; }
.icon-user:before { content: "\f320"; }
.icon-users:before { content: "\f321"; }
.icon-validate:before { content: "\f322"; }
.icon-versions:before { content: "\f323"; }
.icon-vertex:before { content: "\f324"; }
.icon-view-layouts:before { content: "\f325"; }
.icon-view-navigation:before { content: "\f326"; }
.icon-visibility-half:before { content: "\f327"; }
.icon-visibility-hide-2:before { content: "\f328"; }
.icon-visibility-hide:before { content: "\f329"; }
.icon-visibility-invert:before { content: "\f32a"; }
.icon-visibility-semi-transparent:before { content: "\f32b"; }
.icon-visibility:before { content: "\f32c"; }
.icon-walk:before { content: "\f32d"; }
.icon-wifi-disabled:before { content: "\f32e"; }
.icon-wifi:before { content: "\f32f"; }
.icon-window-add:before { content: "\f330"; }
.icon-window-area:before { content: "\f331"; }
.icon-window-backward:before { content: "\f332"; }
.icon-window-collapse-2:before { content: "\f333"; }
.icon-window-collapse:before { content: "\f334"; }
.icon-window-expand:before { content: "\f335"; }
.icon-window-forward:before { content: "\f336"; }
.icon-window-full-screen:before { content: "\f337"; }
.icon-window-maximize:before { content: "\f338"; }
.icon-window-minimize:before { content: "\f339"; }
.icon-window-new:before { content: "\f33a"; }
.icon-window-settings:before { content: "\f33b"; }
.icon-window-split-horizontal:before { content: "\f33c"; }
.icon-window-split-vertical:before { content: "\f33d"; }
.icon-window:before { content: "\f33e"; }
.icon-windows:before { content: "\f33f"; }
.icon-xray-view:before { content: "\f340"; }
.icon-zoom-in-2:before { content: "\f341"; }
.icon-zoom-in:before { content: "\f342"; }
.icon-zoom-out-2:before { content: "\f343"; }
.icon-zoom-out:before { content: "\f344"; }
.icon-zoom:before { content: "\f345"; }
